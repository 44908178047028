// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accordion-mdx": () => import("./../../../src/pages/accordion.mdx" /* webpackChunkName: "component---src-pages-accordion-mdx" */),
  "component---src-pages-alert-dialog-mdx": () => import("./../../../src/pages/alert-dialog.mdx" /* webpackChunkName: "component---src-pages-alert-dialog-mdx" */),
  "component---src-pages-alert-mdx": () => import("./../../../src/pages/alert.mdx" /* webpackChunkName: "component---src-pages-alert-mdx" */),
  "component---src-pages-animation-mdx": () => import("./../../../src/pages/animation.mdx" /* webpackChunkName: "component---src-pages-animation-mdx" */),
  "component---src-pages-auto-id-mdx": () => import("./../../../src/pages/auto-id.mdx" /* webpackChunkName: "component---src-pages-auto-id-mdx" */),
  "component---src-pages-checkbox-mdx": () => import("./../../../src/pages/checkbox.mdx" /* webpackChunkName: "component---src-pages-checkbox-mdx" */),
  "component---src-pages-combobox-mdx": () => import("./../../../src/pages/combobox.mdx" /* webpackChunkName: "component---src-pages-combobox-mdx" */),
  "component---src-pages-component-component-mdx": () => import("./../../../src/pages/component-component.mdx" /* webpackChunkName: "component---src-pages-component-component-mdx" */),
  "component---src-pages-dialog-mdx": () => import("./../../../src/pages/dialog.mdx" /* webpackChunkName: "component---src-pages-dialog-mdx" */),
  "component---src-pages-disclosure-mdx": () => import("./../../../src/pages/disclosure.mdx" /* webpackChunkName: "component---src-pages-disclosure-mdx" */),
  "component---src-pages-funding-mdx": () => import("./../../../src/pages/funding.mdx" /* webpackChunkName: "component---src-pages-funding-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-listbox-mdx": () => import("./../../../src/pages/listbox.mdx" /* webpackChunkName: "component---src-pages-listbox-mdx" */),
  "component---src-pages-menu-button-mdx": () => import("./../../../src/pages/menu-button.mdx" /* webpackChunkName: "component---src-pages-menu-button-mdx" */),
  "component---src-pages-portal-mdx": () => import("./../../../src/pages/portal.mdx" /* webpackChunkName: "component---src-pages-portal-mdx" */),
  "component---src-pages-rect-mdx": () => import("./../../../src/pages/rect.mdx" /* webpackChunkName: "component---src-pages-rect-mdx" */),
  "component---src-pages-skip-nav-mdx": () => import("./../../../src/pages/skip-nav.mdx" /* webpackChunkName: "component---src-pages-skip-nav-mdx" */),
  "component---src-pages-slider-mdx": () => import("./../../../src/pages/slider.mdx" /* webpackChunkName: "component---src-pages-slider-mdx" */),
  "component---src-pages-styling-mdx": () => import("./../../../src/pages/styling.mdx" /* webpackChunkName: "component---src-pages-styling-mdx" */),
  "component---src-pages-tabs-mdx": () => import("./../../../src/pages/tabs.mdx" /* webpackChunkName: "component---src-pages-tabs-mdx" */),
  "component---src-pages-tooltip-mdx": () => import("./../../../src/pages/tooltip.mdx" /* webpackChunkName: "component---src-pages-tooltip-mdx" */),
  "component---src-pages-visually-hidden-mdx": () => import("./../../../src/pages/visually-hidden.mdx" /* webpackChunkName: "component---src-pages-visually-hidden-mdx" */),
  "component---src-pages-window-size-mdx": () => import("./../../../src/pages/window-size.mdx" /* webpackChunkName: "component---src-pages-window-size-mdx" */)
}

